<template lang="html">
  <!-- <img src="@/assets/logo.png" alt="Diensten Centrum" width="200" /> -->
  <h1 class="title text-primary">Vul jouw contactgegevens in</h1>
  <input
    v-model="phone"
    class="form-control field no-focusborder"
    type="text"
    ref="inputfield"
    placeholder="Telefoon"
    required
  />
  <input
    v-model="email"
    class="form-control field no-focusborder"
    type="email"
    ref="inputfield"
    placeholder="E-mailadres"
    required
  />
  {{ validateEmail }}
  <div class="d-grid gap-2">
    <button
      type="button"
      class="btn btn-primary text-light btn-lg"
      :disabled="isDisabled == true"
      @click="setContactData()"
    >
      Volgende
    </button>
  </div>
</template>

<script>
export default {
  name: "ContactDataScreen",
  data: () => ({
    phone: null,
    email: null,
  }),
  computed: {
    isDisabled() {
      if (this.phone && this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return false;
        }
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  methods: {
    setContactData() {
      this.$store.commit("setPhone", this.phone);
      this.$store.commit("setEmail", this.email);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 30px;
  color: #96fa6c;
}
.title {
  padding-bottom: 10px;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 20px;
  color: #96fa6c;
  background-color: #251140;
}
.field::placeholder {
  color: #96fa6c;
  opacity: 0.5;
}
</style>
