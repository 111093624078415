<template>
  <div class="background">
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="scss">
@import "styles/custom.scss";

@import "bootstrap/scss/bootstrap.scss";
// @import '~bootstrap-vue/src/index.scss';

body {
  font-family: "Satoshi-Medium";
}

.background {
  width: 100%;
  height: 100vh;
  background-image: url("@/assets/background.jpg");
  background-size: cover;
}

.heartbeat {
  animation: heartbeat 1s infinite alternate ease-in-out;
  transform-origin: center;
  transform-box: fill-box;
}

/* Heart beat animation */
@keyframes heartbeat {
  to {
    transform: scale(0.8);
  }
}
.cloud {
  animation: cloud 10s infinite alternate ease-in-out;
}
@keyframes cloud {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5%);
  }
}
.ster {
  animation: ster 5s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}
@keyframes ster {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
.swing {
  animation: swinging 1.5s infinite alternate ease-in-out;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes swinging {
  from {
    transform: rotateZ(-3deg);
  }
  to {
    transform: rotate(3deg);
  }
}
.aeroplane {
  animation: aeroplane 3s linear forwards;
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes aeroplane {
  0% {
    transform: translateX(-1000px);
    opacity: 1;
  }
  100% {
    transform: translateX(1500px);
    opacity: 0;
  }
}
</style>
