<template>
  <DataScreen :showrestartbtn="true" />
</template>

<script>
import DataScreen from "@/components/DataScreen.vue";

export default {
  name: "ShowDataScreen",
  components: {
    DataScreen,
  },
  data() {
    return {};
  },
  created() {
    // this.$store.dispatch("updatePlayer");
    this.timer = setInterval(this.gotoBegin, 10000);
  },
  computed: {},
  methods: {
    gotoBegin() {
      this.$store.commit("setGameState", "inactive");
      this.$store.commit("setPlayerState", "ended");
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
