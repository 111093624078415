<template lang="html">
  <!-- <img src="@/assets/logo.png" alt="Diensten Centrum" width="200" /> -->
  <h1 class="title text-primary">Vul jouw gegevens in</h1>
  <input
    v-model="firstName"
    class="form-control field no-focusborder"
    type="text"
    ref="inputfield"
    placeholder="Voornaam"
    required
  />
  <input
    v-model="lastName"
    class="form-control field no-focusborder"
    type="text"
    ref="inputfield"
    placeholder="Achternaam"
    required
  />
  <div class="d-grid gap-2">
    <button
      type="button"
      class="btn btn-primary text-light btn-lg"
      :disabled="isDisabled == true"
      @click="setPersonalData()"
    >
      Volgende
    </button>
  </div>
</template>

<script>
export default {
  name: "PersonalDataScreen",
  data: () => ({
    firstName: null,
    lastName: null,
    email: null,
  }),
  computed: {
    isDisabled() {
      if (this.firstName && this.lastName) {
        return false;
      }
      return true;
    },
  },
  methods: {
    setPersonalData() {
      this.$store.commit("setFirstName", this.firstName);
      this.$store.commit("setLastName", this.lastName);
      // this.$store.commit("setPhone", this.phone);
      // this.$store.commit("setEmail", this.email);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 30px;
  color: #96fa6c;
}
.title {
  padding-bottom: 10px;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 20px;
  color: #96fa6c;
  background-color: #251140;
}
.field::placeholder {
  color: #96fa6c;
  opacity: 0.5;
}
</style>
